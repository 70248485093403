<template>
  <div class="psychological-test-screen">
    <div class="psychological-test-screen-header">
        <h1>Psikolojik Testler</h1>
        <el-button type="primary" size="small" @click="addTest" circle icon="el-icon-plus"></el-button>
    </div>
    <div class="psychological-test-screen-content">
      <el-table :data="tests"  fit style="width: 100%">
        <el-table-column prop="Image" width="130px"  label="Resim">
          <template slot-scope="scope">
            <img :src="scope.row.Image" height="100px" alt="Resim" />
          </template>
        </el-table-column>
        <el-table-column prop="Title"  stripe label="Başlık" />
        <el-table-column prop="CreatedDateTime" align="center" width="200px" label="Oluşturulma Tarihi">
          <template slot-scope="scope">
            {{  $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="threeDot" align="center" label="" width="65px">
          <template slot-scope="scope">
            <el-dropdown>
                <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="goDetails(scope.row)" style="display: block">Düzenle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <span style="display: block" @click="deleteTest(scope.row.ID)">Sil</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PsychologicalTestScreen",
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("getTests", {});
  },
  methods: {
    goDetails(row) {
      this.$router.push('/admin/psikolojik-testler/duzenle/' + row.ID);
    },
    addTest() {
      this.$router.push('/admin/psikolojik-testler/ekle');
    },
    async deleteTest(id) {
        await this.$store.dispatch("deleteTest", {Id: id});
    },
  },
  computed: {
    tests() {
      return this.$store.getters.getTests;
    },
  },
};
</script>

<style>
.psychological-test-screen {
  padding: 20px;
}
.psychological-test-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
